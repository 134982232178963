<template>
  <div>
    <div class="reserve-box">
      <div class="font-30 bold m-b-36">预约信息</div>
      <div class="font-24 flex m-b-32">
        <div class="left sup-color">预约日期</div>
        <div>{{travelDateEnd}}~{{travelDateEnd}}（{{info.travelDateCount}}天）</div>
      </div>
      <div class="font-24 flex m-b-32">
        <div class="left sup-color">出行人</div>
        <div>{{info.traveler}}</div>
      </div>
      <div class="font-24 flex m-b-32">
        <div class="left sup-color">预约份数</div>
        <div>
          <van-stepper v-model="value.quantity" theme="round" button-size="22" :max="maxCount" disable-input />
        </div>
      </div>
      <div class="font-24 flex m-b-32">
        <div class="left sup-color">备注</div>
        <textarea v-model="value.message" class="textarea" maxlength="50" type="text" placeholder="选填，50字以内" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    info: {
      type: Object,
      default() {
        return {};
      },
    },
    value: {
      type: Object,
      default() {
        return {
          message: "",
          quantity: 1,
        };
      },
    },
  },
  watch: {
    info: {
      handler(newVal, oldVal) {
        this.maxCount = newVal.ticketCodes && newVal.ticketCodes.length;
        this.travelDateEnd =
          newVal.travelDateEnd && newVal.travelDateEnd.substring(0, 10);
        this.travelDateBegin =
          newVal.travelDateBegin && newVal.travelDateBegin.substring(0, 10);
          
      },
      immediate: true,
      deep: true,
    },
  },
  data() {
    return {
      quantity: 1,
      message: "",
      maxCount: 1,
      travelDateEnd: "",
      travelDateBegin: "",
    };
  },
};
</script>

<style lang="scss" scoped>
.reserve-box {
  width: 721px;
  background: #ffffff;
  border-radius: 10px;
  margin: 0 auto;
  padding: 36px 30px;
  box-sizing: border-box;
  .left {
    width: 130px;
    margin-right: 14px;
  }
  .textarea {
    border: none;
    width: 80%;
    height: 100px;
  }
}
</style>