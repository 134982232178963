<template>
  <div class="goods-box">
    <div class="flex m-b-30">
      <img :src="info.imagePath" class="goods-img" srcset="">
      <div>
        <div class="font-26 bold goods-name">{{info.orderType == 4 ? info.resourceName : info.productName}}</div>
        <div class="supColor font-22">{{info.orderType == 4 ? info.productName : info.productSkuName}}</div>
      </div>
    </div>
    <div class="font-24 flex m-b-32">
      <div class="left supColor">费用包含</div>
      <ul class="">
        <li class="m-b-10">{{info.costDescription}}</li>
      </ul>
    </div>
    <div class="font-24 flex m-b-32">
      <div class="left supColor">联系人</div>
      <div>{{info.contactsName}}（{{info.contactsPhoneNumber}}）</div>
    </div>
    <div class="font-24 flex m-b-32">
      <div class="left supColor">有效期</div>
      <div>{{validityBegin}} ~ {{validityEnd}}</div>
    </div>
    <div v-if="info.orderTravelers && info.orderTravelers.length > 0" class="font-24 flex-start-center">
      <div class="left supColor">出行人</div>
      <div class="check" @click="showTraveler">查看</div>
    </div>
    <traveler ref="traveler"/>
  </div>
</template>

<script>
import traveler from './traveler.vue'
export default {
  components:{traveler},
  props: {
    info: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  watch: {
    info: {
      handler(newVal, oldVal) {
        this.validityBegin =
          newVal.validityBegin && newVal.validityBegin.substring(0, 10);
        this.validityEnd =
          newVal.validityEnd && newVal.validityEnd.substring(0, 10);
      },
      immediate: true,
      deep: true,
    },
  },
  data() {
    return {
      validityBegin: "",
      validityEnd: "",
    };
  },
  methods:{
    showTraveler(){
      this.$refs.traveler.open(this.info.orderTravelers)
    }
  }
};
</script>

<style lang="scss" scoped>
.goods-box {
  width: 721px;
  border-radius: 10px;
  padding: 30px;
  box-sizing: border-box;
  margin: 0 auto;
  background-color: #fff;
  .goods-img {
    width: 130px;
    height: 130px;
    margin-right: 14px;
  }
  .goods-name {
    width: 408px;
    -webkit-line-clamp: 2;
    margin-bottom: 9px;
    font-size: 28px;
    @include moreEllipsis;
  }
  .left {
    width: 130px;
    margin-right: 14px;
  }
  .check {
    width: 92px;
    padding: 8px 0;
    border-radius: 30px;
    border: 2px solid #00a8a8;
    color: #00A8A8;
    font-size: 24px;
    text-align: center;
  }
}
</style>