<template>
  <van-popup class="popup-box" v-model="show" position="bottom" round closeable safe-area-inset-bottom>
    <div class="title">出行人</div>
    <div class="flex-start-center m-b-24" v-for="(item,index) in travelList" :key="index">
      <div class="index">{{index+1}}</div>
      <div>
        <div class="font-28">{{item.name}}<span class="color-333 m-l-28">{{item.phoneNumber}}</span></div>
        <div class="font-24 color-999">身份证：{{item.idCard}}</div>
      </div>
    </div>
  </van-popup>
</template>

<script>
export default {
  data() {
    return {
      show: false,
      travelList: [],
    };
  },
  methods: {
    open(list) {
      this.travelList = list
      this.show = true;
    },
    close() {
      this.show = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.popup-box{
    padding: 34px;
}
.title {
  text-align: center;
  font-size: 32px;
  font-weight: bold;
  margin-bottom: 40px;
}
.index {
  width: 36px;
  height: 36px;
  line-height: 36px;
  text-align: center;
  border: 2px solid #00a8a8;
  font-size: 26px;
  margin-right: 20px;
  border-radius: 50%;
  color: #00a8a8;
}
</style>