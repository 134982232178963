<template>
  <div>
  <div class="reserve-box">
    <div class="font-30 bold m-b-36">核销信息</div>
    <div class="font-24 flex-start-center m-b-32">
      <div class="left sup-color">核销份数</div>
      <div v-if="info.orderType == 4">1</div>
      <div v-else>
        <van-stepper v-model="value.quantity" theme="round" button-size="22" :max="maxCount" disable-input />
      </div>
    </div>
    <div v-if="selctedResource.text" class="font-24 flex m-b-32">
      <div class="left sup-color">选择资源</div>
      <div @click="open">{{selctedResource.text}}</div>
    </div>
    <div class="font-24 flex m-b-32">
      <div class="left sup-color">备注</div>
      <textarea v-model="value.message" class="textarea" maxlength="50" type="text" placeholder="选填，50字以内" />
    </div>
  </div>
  <van-popup v-model="show" round position="bottom">
    <van-picker title="选择资源" show-toolbar :columns="resourceList" :default-index="0" @confirm="onConfirm" @cancel="close" />
  </van-popup>
  </div>
</template>

<script>
import { resource } from "@/api/writeOff";
export default {
  props: {
    info: {
      type: Object,
      default() {
        return {};
      },
    },
    value: {
      type: Object,
      default() {
        return {
          message: "",
          quantity: 1,
        };
      },
    },
  },
  watch: {
    info: {
      handler(newVal, oldVal) {
        this.maxCount = newVal.ticketCodes && newVal.ticketCodes.length;
        if (newVal.productId && newVal.orderType != 4 && !newVal.needReservation ) {
          this.getResource();
        }
      },
      immediate: true,
      deep: true,
    },
  },
  data() {
    return {
      quantity: 1,
      message: "",
      maxCount: 1,
      show: false,
      resourceList: [],
      selctedResource: {},
    };
  },
  methods: {
    open() {
      this.show = true;
    },
    close() {
      this.show = false;
    },
    // 获取资源
    async getResource() {
      const res = await resource({ productId: this.info.productId });
      let arr = [];
      for (let item of res) {
        for (let child of item.resourceInfos) {
          arr.push(child);
        }
      }
      this.resourceList = arr.map((item) => {
        item.value = item.id
        item.text = item.name
        return item
      });
      this.selctedResource = this.resourceList[0];
    },
    onConfirm(value, index) {
      this.selctedResource = this.resourceList[index];
      this.close();
    },
  },
};
</script>

<style lang="scss" scoped>
.reserve-box {
  width: 721px;
  background: #ffffff;
  border-radius: 10px;
  margin: 0 auto;
  padding: 36px 30px;
  box-sizing: border-box;
  .left {
    width: 130px;
    margin-right: 14px;
  }
  .textarea {
    border: none;
    width: 80%;
    height: 100px;
  }
}
</style>