<template>
  <div class="box">
    <goods class="m-b-14" :info="info" />
    <!-- <reserve :info="info" v-model="otherInfo" /> -->
    <reserve v-if="info.reservationOrderId != '0' && info.orderType != 4" :info="info" v-model="otherInfo" />
    <count ref="count" v-else :info="info" v-model="otherInfo" />
    <fixedBottomButton text="立即核销" @buttonClick="showConfirm" />
  </div>
</template>

<script>
import goods from "./components/goods.vue";
import reserve from "./components/reserve.vue";
import count from "./components/count.vue";
import fixedBottomButton from "@/components/common/fixed-bottom-button.vue";
import { Dialog } from "vant";
import { resourceUrl } from "@/config";
import { detail,writeOffTicket } from "@/api/writeOff";
export default {
  components: { goods, reserve, count, fixedBottomButton },
  data() {
    return {
      info: {},
      config: {},
      otherInfo: {
        message: "",
        quantity: 1,
      },
    };
  },
  mounted() {
    this.getInfo();
  },
  methods: {
    // 获取二维码内容
    getInfo() {
      detail({
        ticketCodes: this.$route.query.ticketCodes.split(","),
      }).then((res) => {
        res.imagePath = `${resourceUrl}/${res.imagePath}`;
        this.info = res
      });
    },
    immediateWriteOff() {
      let params = {
        orderType: this.info.orderType,
        baseOrderId: this.info.baseOrderId,
        reservationOrderId: this.info.reservationOrderId,
        usedSource: 3,
        message: this.otherInfo.message,
        quantity: this.otherInfo.quantity,
        ticketCodes:this.$route.query.ticketCodes.split(","),
      }
      if(this.info.orderType == 1 && this.info.needWriteOff && !this.info.needReservation){
        params.resourceId = this.$refs.count.selctedResource.id
        params.resourceName = this.$refs.count.selctedResource.name
        params.resourceType = this.$refs.count.selctedResource.resourceType
      }
      writeOffTicket(params).then((res) => {
        console.log(res);
        if (res) {
          this.$toast("核销完成");
          setTimeout(() => {
            this.$router.go(-1);
          }, 1500);
        }
      });
    },
    showConfirm() {
      Dialog.confirm({
        message: "确认核销？",
        confirmButtonColor: "#2568FF",
      }).then(() => {
        this.immediateWriteOff();
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.box {
  background-image: linear-gradient(360deg, #f4f5f9 0%, #2568ff 100%);
  background-size: 100% 197px;
  background-repeat: no-repeat;
  background-position: top center;
  padding-top: 18px;
}
</style>